import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { Message } from '@arco-design/web-react';
import useStorage from '@/utils/useStorage';

// axios 实例
const instance = axios.create({
  baseURL: '/api/v1',
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response) {
      if (response.request.responseType === 'blob') {
        if (
          response.data instanceof Blob &&
          response.data.type === 'application/json'
        ) {
          response.data.text().then((text) => {
            const json = JSON.parse(text);
            Message.error(json?.message);
            return Promise.reject(json);
          });
        }
        return response;
      }
      const { code } = response.data;
      if (code === 200) {
        return response;
      }
      switch (
        code // 响应状态码
      ) {
        case 400:
          Message.error('请求参数错误');
          break;
        case 401:
          Message.error(response?.data?.message);
          localStorage.removeItem('token');
          localStorage.removeItem('userStatus');
          localStorage.removeItem('username');
          let loginPath = '/login';
          const currentPath = window.location.pathname;
          currentPath !== '/' &&
            (loginPath += `?redirect=${encodeURI(currentPath)}`);
          window.location.href = loginPath;
          break;
        case 403:
          Message.error('您无权访问');
          break;
        case 404:
          Message.error('访问资源不存在');
          break;
        case 500:
          Message.error('服务器内部错误');
          break;
        case 501:
          Message.error(response?.data?.message);
          break;
        default:
          if (code && code !== 200) {
            Message.error(response?.data?.message);
            return Promise.reject({ code, data: response?.data.data });
          }
          return Promise.reject('请求失败');
      }
      return Promise.reject('请求失败');
    } else {
      // Message.error('网络连接异常,请稍后再试!');
      return Promise.reject('网络连接异常,请稍后再试!');
    }
  },
  (error) => {
    const { response } = error;
    if (axios.isCancel(error)) {
      // cancel的请求不需要Message
      return Promise.reject(error);
    }
    const msg =
      typeof response?.data === 'string'
        ? response.data
        : response?.data?.message || '网络连接异常,请稍后再试!';
    Message.error(msg);
    return Promise.reject(error);
  }
);

// response
export interface ResponseData<T> {
  code: number;
  message: string;
  data: T;
}

const request = async <T = any>(config: AxiosRequestConfig): Promise<T> => {
  try {
    const { data } = await instance.request<ResponseData<T>>(config);
    return data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const download = async <T = any>(
  config: AxiosRequestConfig
): Promise<any> => {
  try {
    const response = await instance.request<T>(config);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const get = async <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return request<T>({ ...config, method: 'GET' });
};

export const post = async <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return request<T>({ ...config, method: 'POST' });
};

export const put = async <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return request<T>({ ...config, method: 'PUT' });
};

export const deleteReq = async <T = any>(
  config: AxiosRequestConfig
): Promise<T> => {
  return request<T>({ ...config, method: 'DELETE' });
};
