import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Message,
  Modal,
  Space,
} from '@arco-design/web-react';
import { IconDown, IconPoweroff, IconUser } from '@arco-design/web-react/icon';
import useLocale from '@/utils/useLocale';
import Logo from '@/assets/logo.svg';
import styles from './style/index.module.less';
import useStorage from '@/utils/useStorage';
import { useHistory } from 'react-router-dom';
import packageJson from '../../../package.json';
import { DataSync } from '@/services/data';
import { GlobalState } from '@/store';
import { useSelector } from 'react-redux';
import { addUser } from '@/services/user';

function Navbar() {
  const t = useLocale();
  const history = useHistory();
  const [, , removeUserStatus] = useStorage('userStatus');
  const [username, , removeUsername] = useStorage('username');
  const [, , removeToken] = useStorage('token');
  const { userInfo } = useSelector((state: GlobalState) => state);
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const [form] = Form.useForm();

  function logout() {
    removeUserStatus();
    removeUsername();
    removeToken();
    let loginPath = '/login';
    const currentPath = window.location.pathname;
    currentPath !== '/' && (loginPath += `?redirect=${encodeURI(currentPath)}`);
    window.location.href = loginPath;
  }

  function onMenuItemClick(key: string) {
    if (key === 'logout') {
      logout();
    } else {
      Message.info(`You clicked ${key}`);
    }
  }

  function modalConfirm() {
    Modal.confirm({
      title: '提示',
      content: '确认需要同步数据吗？',
      onOk: () =>
        new Promise((resolve, reject) => {
          DataSync()
            .then(() => {
              Message.success('同步成功');
              resolve(true);
            })
            .catch(() => reject(false));
        }),
    });
  }

  async function submitAddUser() {
    console.log(userInfo);
    try {
      const values = await form.validate();
      console.log(values);
      setAddUserLoading(true);
      await addUser({ ...values, role_id: 2 }); // role_id: 2 - 普通用户
      Message.success('添加成功');
      setAddUserVisible(false);
    } catch (e) {
      console.log(e);
    } finally {
      setAddUserLoading(false);
    }
  }

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      {/*<Menu.Item key="setting" disabled><IconSettings className={styles['dropdown-icon']} />修改密码</Menu.Item>*/}
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        {t['navbar.logout']}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo} onClick={() => history.push('/')}>
          <Logo />
          <div className={styles['logo-name']}>{packageJson.productName}</div>
        </div>
      </div>
      <ul className={styles.right}>
        {userInfo?.root && (
          <li>
            <Button
              size="small"
              type="secondary"
              onClick={() => setAddUserVisible(true)}
            >
              添加用户
            </Button>
          </li>
        )}
        {/*<li>*/}
        {/*  <Button size="small" type="secondary" onClick={modalConfirm}>*/}
        {/*    数据同步*/}
        {/*  </Button>*/}
        {/*</li>*/}
        <li>
          <Dropdown droplist={droplist} position="bottom">
            <Space align="center" size="mini" style={{ cursor: 'pointer' }}>
              <Avatar size={28} style={{ cursor: 'pointer' }}>
                <IconUser />
              </Avatar>
              <span className={styles.username}>{username}</span>
              <IconDown className={styles['dropdown-icon']} />
            </Space>
          </Dropdown>
        </li>
      </ul>
      <Modal
        title="添加用户"
        visible={addUserVisible}
        onOk={submitAddUser}
        confirmLoading={addUserLoading}
        onCancel={() => setAddUserVisible(false)}
      >
        <Form form={form}>
          <Form.Item
            label="账号"
            field="username"
            rules={[
              { required: true, message: '必填项' },
              {
                match: /^[a-zA-Z0-9_-]{0,30}$/,
                message: '最多30个字符，支持英文、数字、-、_',
              },
            ]}
          >
            <Input onPressEnter={submitAddUser} />
          </Form.Item>
          <Form.Item
            label="昵称"
            field="name"
            rules={[{ required: true, message: '必填项' }, { maxLength: 30 }]}
          >
            <Input onPressEnter={submitAddUser} />
          </Form.Item>
          <Form.Item
            label="密码"
            field="password"
            rules={[{ required: true, message: '必填项' }, { maxLength: 30 }]}
          >
            <Input onPressEnter={submitAddUser} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Navbar;
