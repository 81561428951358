import { post } from './index';

/**
 * 用户登录
 */
export const login = (data: { username: string; password: string }) => {
  return post<Record<'name' | 'username' | 'token', string>>({
    url: '/user/login',
    data,
  });
};

export const addUser = (data: {
  username: string;
  name: string;
  password: string;
  role_id: number;
}) => {
  return post({
    url: '/user/add',
    data,
  });
};
