import { Button, Form, Input, Space } from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import { useRef, useState } from 'react';
import useStorage from '@/utils/useStorage';
import styles from './style/index.module.less';
import { login } from '@/services/user';
import qs from 'query-string';

export default function LoginForm() {
  const formRef = useRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [, setToken] = useStorage('token');
  const [, setUsername] = useStorage('username');
  const [, setLogin] = useStorage('userStatus');
  const query = qs.parseUrl(window.location.search)?.query || {};

  function onSubmitClick() {
    formRef.current
      .validate()
      .then((values) => {
        setLoading(true);
        login(values)
          .then((res) => {
            setToken(res.token);
            setUsername(res.username);
            setLogin('login');
            window.location.href = (query?.redirect as string) || '/';
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => err);
  }

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}> 欢迎登录 </div>
      <Form className={styles['login-form']} layout="vertical" ref={formRef}>
        <Form.Item
          field="username"
          rules={[{ required: true, message: '用户名不能为空' }]}
        >
          <Input
            autoComplete="off"
            prefix={<IconUser />}
            placeholder="用户名"
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Form.Item
          field="password"
          rules={[{ required: true, message: '密码不能为空' }]}
        >
          <Input.Password
            autoComplete="off"
            prefix={<IconLock />}
            placeholder="密码"
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <Button type="primary" long onClick={onSubmitClick} loading={loading}>
            登录
          </Button>
          {/*<Button*/}
          {/*  type="text"*/}
          {/*  long*/}
          {/*  className={styles['login-form-register-btn']}*/}
          {/*>*/}
          {/*  注册账号*/}
          {/*</Button>*/}
        </Space>
      </Form>
    </div>
  );
}
