import auth, { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
import { GlobalState } from '@/store';
import { useSelector } from 'react-redux';

export type IRoute = AuthParams & {
  name: string;
  key: string;
  componentKey?: string; // 组件位置，优先级高于key；若父组件路由需要渲染组件，必须用这个
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
  root?: boolean; // 是否root路由
};

export const routes: IRoute[] = [
  {
    name: '应用',
    key: 'app',
    componentKey: 'app',
    children: [
      {
        name: '版本',
        key: 'app/version',
        ignore: true,
      },
    ],
  },
  {
    name: '标签',
    key: 'category',
    root: true,
  },
  {
    name: '下载记录',
    key: 'download-record',
    root: true,
  },
  {
    name: 'aidlux版本',
    key: 'aidlux',
    root: true,
  },
  {
    name: '平台系统分类',
    key: 'platform',
    root: true,
  },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

export const generatePermission = (role: string) => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = (userPermission): [IRoute[], string] => {
  const { userInfo } = useSelector((state: GlobalState) => state);
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };
  // 过滤root路由
  const filterRootRoute = (routes: IRoute[]): IRoute[] => {
    if (userInfo.root) {
      return routes;
    }
    return routes.filter((route) => !route.root);
  };

  const [permissionRoute, setPermissionRoute] = useState(
    filterRootRoute(routes)
  );

  useEffect(() => {
    const newRoutes = filterRoute(routes);
    const filterRoutes = filterRootRoute(newRoutes);
    setPermissionRoute(filterRoutes);
  }, [JSON.stringify(userPermission)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.componentKey
        ? first.key
        : first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
